.tabs {
  margin-top: 16px;
}

.tab-list:hover{
    cursor: pointer;
}

.tab-list-item {
  border-bottom: 2px solid #D8D8D8;
  color: #767676;
  display: inline-block;
  font-size: 14px;
  list-style: none;
  margin-bottom: 10px;
  opacity: 0.5;
  padding: 0 0.4rem 0.4rem 8px;
  text-align: center;
  width: 50%;
  }

.tab-list-active {
  border-bottom: 6px solid #63A3F0;
  color: #262626;
  opacity: 1;
}