.loader {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 500px;
}

@keyframes loader-ani1 {
  50% {
   opacity: 0.3;
   transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#wrapper_loader {
  align-items: center;
  animation-direction: alternate;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
  background-color: transparent;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: fixed;
  top: 40%;
  z-index: 9999;
}

#wrapper_loader .loader-ani1 {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

#wrapper_loader .loader-ani1 .circle,
#wrapper_loader .loader-ani1:after,
#wrapper_loader .loader-ani1:before {
  animation-direction: alternate;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-name: loader-ani1;
  animation-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
  background-color: #5394be;
  border-radius: 50%;
  content: "";
  display: block;
  height: 10px;
  margin: 0 3px;
  opacity: 0.3;
  position: relative;
  transform: scale(0.5);
  transform-origin: center;
  width: 10px;
  will-change: transform opacity;
}

#wrapper_loader .loader-ani1:before {
  animation-delay: 0ms;
}

#wrapper_loader .loader-ani1 .circle {
  animation-delay: 125ms;
}

#wrapper_loader .loader-ani1:after {
  animation-delay: 250ms;
}

#wrapper_loader.loader-fadein {
  animation-name: fadein;
  opacity: 1;
  pointer-events: all;
}
