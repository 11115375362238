.credit-card-container {
    align-items: center;
    background: var(--neutrals-white-opacity-100, #FFF);
    border: 1px solid var(--neutrals-grey-25, #BEBEBE);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-bottom: 158px;
    margin-top: 164px;
    padding: 48px 40px;
    width: 450px;   
}

.product-icon-style{
    height: 64px;
    width: 64px;
}

.dashboard-headline, .dashboard-headline-one{
    color: #262626;
    font-family: Open Sans;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
}

.dashboard-headline-one{
    margin-top: 24px;
}

.credit-card-container-text{
    margin-top: 24px;
}

.dashboard-container {
    align-items: center;
    background: #FFF;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 158px;
    margin-top: 164px;
    padding: 40px 60px 48px 60px;
}

.product-entitlement-container {
    align-items: flex-start;
    display: flex;
    gap: 32px;
    margin-top: 40px;
}

.product-style {
    position: relative;
    align-items: center;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 284px;
    justify-content: center;
    padding: 24px 40px;
    text-decoration: none;
    transition: border 0.15 ease;
}

.product-style::before,
.product-style::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #D8D8D8;
    /* pointer-events: none; */
}

.product-style:hover::before,
.product-style:hover::after {
    border: 3px solid #0A5EC0;
    margin: -2px;
    cursor: pointer;
}

.product-image-style {
    flex-shrink: 0;
    height: 148px !important;
    width: 181px !important;
}

.product-text-style {
    color: #262626;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-top: 32px;
    text-align: center;
}

.credit-card-container {
    align-items: center;
    background: var(--neutrals-white-opacity-100, #FFF);
    border: 1px solid var(--neutrals-grey-25, #BEBEBE);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin-bottom: 158px;
    margin-top: 164px;
    padding: 48px 40px;
    width: 450px;
}

.session-timeout-popup {
    display: inline-flex;
    padding: 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 8px;
    border: 1px gray;
    background: #FFF;
    margin: 140px 0;
    z-index: 10;
}

.refresh {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
}

.popup-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.timer {
    display: flex;
    width: 432px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.fail {
    display: flex;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
}

.timer h4 {
    align-self: stretch;
    text-align: center;
    line-height: 32px;
    margin: 0;
}

.timer .subtext {
    align-self: stretch;
    text-align: center;
    line-height: 24px;
    /* 150% */
}

.timer .time {
    color: var(--feedback-dark-red, #C60F13);
    text-align: center;
    /* Body/Semibold XLarge */
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.buttons {
    display: flex;
    width: 432px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    letter-spacing: -0.107px;
}

.buttons .still-here {
    height: 48px;
    padding: 12px 56px !important;
    margin: 0 !important;
}

.buttons .sign-out {
    padding: 0 8px !important;
    margin: 0 !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px !important;
    color: var(--primary-brand-blue, #0A5EC0);
    letter-spacing: -0.107px;
}

.buttons .sign-in {
    display: flex;
    width: 266px;
    height: 48px;
    padding: 12px 32px 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0 !important;
}

.session-timeout-popup.loaders {
    width: 528px;
    height: 422px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: block;
    z-index: 9;
}

.blurred a {
    pointer-events: none;
    cursor: not-allowed;
}


@media (max-width: 710px) {
    .dashboard-container {
        margin-top: 70px !important;
        padding: 24px 16px !important;
        margin-bottom: 63px !important;
    }
        
    .credit-card-container{
        width: 100%;
        max-width: 500px;
    }
        
    .product-style {
    padding: 24px 96px !important;
    height: 170px !important;
    }
    
    .product-image-style {
    max-height: 84px!important;
    max-width: 103px!important;
    }
    
    .dashboard-headline, .dashboard-headline-one {
    font-size: 20px !important;
    }

    .product-entitlement-container {
        display: block;
        margin-top: 16px !important;
    }

    .product-entitlement-container>a:first-child {
        margin-bottom: 9px;
    }

   
    .product-text-style {
        font-size: 16px !important;
        margin-top: 16px !important;
    }

    .session-timeout-popup {
        padding: 32px 32px 40px 32px !important;
        max-width: 343px;
        width: 90%;
    }

    .popup-content {
        gap: 24px;
    }

    .timer {
        gap: 16px;
        max-width: 279px;
        width: 100%;
        line-height: 24px;
    }

    .timer h4 {
        font-size: 20px !important;
    }

    .timer .subtext {
        align-self: stretch;
        text-align: center;
        line-height: 24px;
        /* 150% */
    }

    .buttons {
        max-width: 279px;
        width: 100%;
        align-self: stretch;
    }

    .buttons .still-here {
        padding: 12px 32px 12px 24px !important;
        gap: 8px;
        align-self: stretch;
    }

    .session-timeout-popup.loaders {
        width: 343px !important;
        height: 440px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .buttons .sign-in {
        max-width: 279px;
        width: 100%;
        align-self: stretch;
    }
}
