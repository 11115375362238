@media (max-width: 972px) {
    /* .bg {
        padding-left: 16px;
        padding-right: 16px;
    } */

    .resend-activation-image,
    .activation-image {
        display: none;
    }

    .web-title {
        display: none;
    }

    .mobile-title {
        display: block;
    }

    .mobile-progress-stepper {
        display: flex !important;
    }

    .web-progress-stepper {
        display: none !important;
    }
}

@media (max-width: 532px) {
    .bg {
        padding-left: 16px;
        padding-right: 16px;
    }

    .form-element-activate-user,
    .form-element-enroll,
    .form-element-enroll-email-next-text,
    .form-element-token-expired,
    .form-element-enroll-text-success,
    .form-element-verify-code {
        margin-top: 24px !important;
        width: 100%;
    }
    .form-element-activate-user,
    .form-element-enroll,
    .form-element-enroll-email-next-text,
    .form-element-token-expired,
    .form-element-enroll-text-success,
    .form-element-verify-code,
    .form-element-resend-activation,
    .form-element-activation-success
    {
        padding: 32px 24px;
    }


    .form-element-resend-activation{
       width: 100%;
    }

    .form-element-activation-success {
        margin-bottom: 120px !important;
    }

    .set-up-icon {
        display: none;
    }

    .button-style {
        font-size: 16px !important;
    }

    .Header__img {
        max-width: 138px !important;
    }

    .Header--dark.PY-1.header-style {
        padding-bottom: 0.75rem !important;
        padding-top: 3rem !important;
    }

    /* .text-element {
        max-width: 250px !important;
        min-width: 250px !important;
    } */
}

@media (max-width: 482px) {

    .form-element,
    .form-element-success,
    .form-element-session-expired,
    .form-element-error,
    .form-element-username-success,
    .form-element-unlock-success,
    .form-element-short,
    .form-element-resetmfa-success,
    .form-element-resend-success {
        margin-top: 24px !important;
        width: 100%;
    }

    .button-style-success {
        margin-top: 0 !important;
    }

    .form-element,
    .form-element-success,
    .form-element-session-expired,
    .form-element-error,
    .form-element-username-success,
    .form-element-unlock-success,
    .form-element-short,
    .form-element-resetmfa-success,
    .form-element-resend-success,
    .form-element-activation {
        padding: 32px 24px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .bg {
        padding-left: 16px;
        padding-right: 16px;
    }

    .progress-stepper-style {
        margin-top: 42px;
        width: 80%;
    }

    .mobile-progress-stepper {
        display: flex !important;
    }

    .web-progress-stepper {
        display: none !important;
    }

    .div-container,
    .div-container-activation {
        /* width: 400px; */
        border: none;
        margin-top: 24px;
        min-height: auto;
    }

    .header-style {
        padding-top: 3rem !important;
    }

    .web-title,  .web-display {
        display: none;
    }

    .mobile-title, .mobile-display {
        display: block;
    }

    .button-style, .button-style-4, .button-style-success, .button-style-fp, .button-style-2, .button-style-3  {
        height: 40px;
        padding: 7px 16px !important;
    }

    .button-style {
        margin-top: 16px !important;
    }

    .button-style-4 {
        margin-top: 48px !important;
     }

    /* .button-style-success {
        height: 40px;
        padding: 7px 16px !important;
    }

    .button-style-fp {
        height: 40px;
        padding: 7px 16px !important;
    } */

    /* .button-style-2 {
        height: 40px;
        padding: 7px 16px !important;
    }

    .button-style-3 {
        height: 40px;
        padding: 7px 16px !important;
    } */

    .feedback-alert {
        margin-top: 24px;
    }

    /* .web-display {
        display: none;
    } */

    /* .mobile-display {
        display: block;
    } */
}

@media (max-width : 320px) {
    .progress-stepper-style {
        margin-top: 48px;
        width: 80%;
    }
}