.button-style {
    /* gap: 8px; */
    align-items: center;
    align-self: stretch;
    background: #0A5EC0;
    border-radius: 60px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin-top: 32px !important;
    order: 8;
    width: 100%;
   }
  
  .button-style-success{
    /* gap: 8px; */
    align-items: center;
    align-self: stretch;
    background: #0A5EC0;
    border-radius: 60px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin-top: 16px !important;
    order: 8;
    width: 100%;
  }
  
  .button-style-fp {
    /* gap: 8px; */
    align-items: center;
    align-self: stretch;
    background: #0A5EC0;
    border-radius: 60px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin-top: 24px !important;
    order: 8;
    width: 100%;
   }

  .button-style-2 {
    /* gap: 8px; */
    align-items: center;
    align-self: stretch;
    background: #0A5EC0;
    border-radius: 60px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin-top: 34px !important; 
    order: 8;
    width: 100%;
  }

  .button-style-3 {
    /* gap: 8px; */
    align-items: center;
    align-self: stretch;
    background: #0A5EC0;
    border-radius: 60px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin-top: 40px !important; 
    order: 8;
    width: 100%;
  }

  .button-style-4 {
     /* gap: 8px; */
    align-items: center;
    align-self: stretch;
    background: #0A5EC0;
    border-radius: 60px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin-top: 56px !important; 
    order: 8;
    width: 100%;
  }

  .Button.link:focus, .Button.link:hover {
    color: #0955AD !important;
    outline: none;
    text-decoration: underline !important;
   }
  
   .button-style, .button-style-4, .button-style-3, .button-style-2, .button-style-fp, .button-style-success {
    margin-bottom: 0 !important;
    padding: 12px 32px;
   }
  /* .Button.link:hover {
    color: #0955AD !important;
    outline: none;
    text-decoration: underline !important;
  } */