.Footer {
  background-color: #161616 !important;
  flex: 1;
}

.Footer__navigation__button .Icon {
  padding: 0 !important;
}

.Footer__navigation__button{
  font-size: 12px !important;
  opacity: 1 !important;
  padding-right: 1rem !important;
}

.Footer__utility_nav__links__link a {
  font-size: 12px !important;
}

.Footer__utility_nav__copyright {
  display: inline-block !important;
  font-size: 12px !important;
}

.Footer__utility_nav__links__link svg {
  margin-left: 7px;
  order: 2 !important;
}
