.alert-message-box {
  overflow: hidden;
}

.alert-content {
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  padding: 1rem;
  position: relative;
  text-align: left;
}

.alert-content-error {
  background-color: #ffe2dd;
  border-color: #c60f1340;
  border-color: rgba(198, 15, 19, 0.25);
  color: #c60f13;
}

.alert-content-success {
  background-color: #dbfbda;
  border: 1px solid #b9dbc7;
}

.alert-icon {
  margin-right: 1rem;
  max-height: 1.5rem;
  min-width: fit-content;
}

.Icon-utility {
  height: 1.5rem;
  min-width: 1.5rem;
  width: 1.5rem;
}

.alert-error-title {
  color: #c60f13;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 8px !important;
}

.alert-success-title {
  color: #424242;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.alert-error-description {
  color: #424242;
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.alert-success-description {
  color: #424242;
  margin-bottom: 0;
  margin-top: 0.5rem;
}
