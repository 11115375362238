.form-element-enroll {
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  margin: 40px 0 0 0;
  max-width: 500px;
  padding: 48px 40px;
}

.form-element-enroll-email-next-text {
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  margin: 40px 0 277px 0;
  max-width: 500px;
  padding: 48px 48px;
}

.form-element-verify-code {
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  margin: 40px 0 0 0;
  max-width: 500px;
  padding: 48px 48px;
}

.form-element-enroll-text-success {
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  margin: 40px 0 293px 0;
  max-width: 500px;
  padding: 48px 48px;
}

.form-element-activation-success {
  background: #FFFFFF;
  border: 1px solid #BEBEBE;
  border-radius: 3px;
  margin: 40px 0 405px 0;
  max-width: 500px;
  padding: 48px 40px;
}

.row {
    /* height: 56px; */
  align-items: center;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 1;
  /* gap: 24px; */
  max-width: 420px;
  order: 0;
  padding: 0;
}

.column {
  float: left;
}

.enroll-icon {
  flex: none;
  flex-grow: 0;
  height: 24px;
  order: 0;
  width: 24px;
}

.text-element {
  margin-right: 24px;
  overflow-wrap: break-word;
  width: 60%;
}

.card-text {
  display: inline;
  font-size: 20px;
}

.card-text-data {
  display: block;
  font-size: 20px;
}

.Toggle {
  margin-left: -10px;
  margin-right: -40px;
}

.toggle-switch {
  display: table-cell;
  flex: none;
  margin-left: -25px;
  order: 2;
}

.row-margin {
  margin-top: 32px;
}

@media (max-width: 532px) {
  .card-text {
    font-size: 16px;
  }

  .card-text-data {
    font-size: 16px;
  }

  .text-element{
    margin-right: 0;
  }
}


@media (max-width: 450px) {
  .text-element{
    width: 51%;
  }
}

@media (max-width: 345px) {
  .text-element{
    width: 30%;
  }
}