.factor-option{
    /* width: 370px; */
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 3px;
    display: flex;
    flex: none;
    flex-direction: row;
    flex-grow: 1;
    /* gap: 16px; */
    height: 96px;
    order: 0;
    padding: 16px 32px 16px 16px;
}

.mfa-factor {
    margin-top: 24px;
}

.p-center-align{
    text-align: center;
}

#resend-button[disabled]
{
    color: #a1a1a1;
    cursor: not-allowed;
}

.mfa-container{
     min-height: 313px !important;
}
.factor-text{
    align-items: center;
    color: #424242;
    display: block;
    flex: none;
    flex-grow: 1;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    height: 56px;
    letter-spacing: -0.11px;
    line-height: 28px;
    margin-left: 10px;
    order: 1;
    width: 242px;
}

.factor-text p{
    font-weight: bold;
}

.factor-icon{
background: #FFFFFF;
bottom: 32.48%;
left: 38.95%;
position: absolute;
right: 39.81%;
top: 30.69%;
}

.factor-link {
    align-items: center;
    display: flex;
    text-decoration: none;
}

.resend-code p {
    align-self: stretch;
    color: #424242;
    flex: none;
    flex-grow: 0;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    height: 24px;
    letter-spacing: -0.11px;
    line-height: 24px;
    order: 0;
    text-align: center;
    width: 370px;
}

.resend-code{
    margin-top: 24px;
    text-align: center;
}

#resend-button{
    background: none;
    border: none;
    color: #0a5ec0;
    cursor: pointer;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.11px;
    line-height: 24px;
}

.back-to-factors{
    margin-top: 32px;
    text-align: center;
    }

#back-to-factors{
    text-decoration: none !important;
    text-decoration-line: none !important;
    }

.masked-factors {
    font-weight: bold;
}