.resend-activation-image {
  align-self: stretch;
  background-color: #ffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  margin: 0 0;
  max-width: 450px;
  min-height: 641px;
  text-align: center;
  width: 450px;
}

.resend-activation-image, .activation-image {
  background-image: url("../assets/ResendActivationPromo.png");
}

.activation-image {
  align-self: stretch;
  background-color: #ffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  margin: 0 0;
  max-width: 450px;
  min-height: 525px;
  text-align: center;
  width: 450px;
}

.promo-image {
  height: 100%;
}

.promo-image-2 {
  height: 100%;
  width: 100%;
}

.set-up-icon {
  text-align: center;
}

.set-up-icon-enroll{
  text-align: center;
}

.set-up-icon-success {
  margin-bottom: 24px;
  text-align: center;
}

.set-up-icon-activation-success {
  margin-bottom: 16px;
  text-align: center;
}

.style-set-up-icon {
  color: #166bcd;
}

.style-enroll-security-icon {
  color: #7eb3f1;
}

.style-checkmark-icon {
  color: #008638;
}

.Header--dark {
  background-color: #0a5ec0 !important;
}

.Button.solid:hover {
  background-color: #0955AD !important;
  border-color: #0955AD !important;
}

.alert-button Button.solid:hover {
  background-color: #c60f13 !important;
  border-color: #c60f13 !important;
}

.governmentIdentifier-input {
  margin-top: 20px;
}

ol,
ul {
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}

.mobile-progress-stepper{
  display: none !important;
}

.mobile-title{
  display: none;
}

.progress-stepper-style {
  margin-top: 64px;
  width: 400px;
}

.ProgressIndicator__step__wrapper__button--completed, .ProgressIndicator__step__wrapper {
  background-color: #e9e9e9 !important;
}

.ProgressIndicator__step__wrapper {
  border: 0.25rem solid #e8e8e8 !important;

}

.progress-stepper-style p {
  color: #231F20 !important;
  font-size: 14px;
  margin-bottom: 8px !important;
}

.ProgressIndicator hr {
  border: 1px solid #bebebe !important
}

.all-completed div:nth-child(4) {
  display: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-reveal 
{
  display: none;
}

.voice-call-icon-style {
  padding: 12px;
}