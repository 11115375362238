.checklist-container {
  /* margin-top: 10px; */
  margin-bottom: 26px;
}

.password-checklist-title {
  font-weight: bold;
}

.checklists-content {
  font-size: 0.8rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.checklist {
  display: inline;
  font-size: 14px;
}

.no-bullet{
  list-style: none;
  margin-left: -1rem;
}

.icon{
  vertical-align: middle;
}